/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

// https://www.gatsbyjs.com/docs/add-seo-component/

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

function SEO({ description, lang, meta, title, children, image, type }) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query SEO {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            defaultAuthor: author
            siteUrl: url
            defaultImage: image
            defaultType: type
          }
        }
      }
    `
  )

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    defaultType,
  } = site.siteMetadata

  // const metaDescription = description || site.siteMetadata.description

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    type: type || defaultType,
  }

  return (
    <Helmet title={`${seo.title} | NOFNEC`}>
      <script
        async={true}
        defer={true}
        crossOrigin="anonymous"
        src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v6.0&appId=1003338746797858&autoLogAppEvents=1"
      />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {/* Only add open graph meta tags if the component provides these values */}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(type ? true : null) && <meta property="og:type" content={seo.type} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {children}
    </Helmet>
  )
}

SEO.defaultProps = {
  description: null,
  lang: `en`,
  meta: [],
  title: null,
  image: null,
  type: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  type: PropTypes.string,
}

export default SEO
